import React from 'react';
import Layout from '../components/layout';

function contact() {
    return (
        <Layout>
            <div className="grid-item">
                <div className="grid-content">
                    <div id="contactDiv">
                        <form name="contact" method="POST" data-netlify="true" action="thank-you" id="contactForm">
                            <input type="hidden" name="form-name" value="contact" />
                            <div className="from-group" id="emailForm">
                                <input type="email" name="email" className="form-control" id="email" placeholder="Your Email" />
                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" />
                                <textarea name="message" className="form-control" id="message" placeholder="Your Message"></textarea>
                                <button className="btn-sm btn-primary" id="btnSend" >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default contact;